<template>
  <div class="page">
    <Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading" :searchFormData="searchFormData" :showRefresh="true" :showSearch="true"
      :showAdd="$hasAccess('device.repaire/add')" addName="新增报修" @getList="getList" @clearSearch="clearSearch" @addItemInTable="AddOne">

      <!--搜索自定义内容-->
      <el-form-item slot="search-item">
        <el-input v-model="searchFormData.device_id" size="small" clearable placeholder="请按设备ID查询"></el-input>
      </el-form-item>

      <!--表格内容-->
      <vxe-table-column slot="table-item" field="record_id" title="报修记录ID" align="center" min-width="80px" />
      <vxe-table-column slot="table-item" field="device_id" title="所属设备" align="center" min-width="200px">
        <template v-slot="{ row }">
          <div v-if="!row.device_id">无</div>
          <div v-if="row.device_id">{{row.device_name}}</div>
          <div class="vxe-sub" v-if="row.device_id">编号:{{row.device_no}}</div>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="plug_num" title="插座编号" align="center" min-width="60px" />
      <vxe-table-column slot="table-item" field="plug_name" title="插座名称" align="center" min-width="60px" />
      <vxe-table-column slot="table-item" field="create_user_name" title="报修人" align="center" min-width="80px" />
      <vxe-table-column slot="table-item" field="check_user_name" title="处理人" align="center" min-width="80px" />
      <vxe-table-column slot="table-item" field="status" title="处理状态" align="center" min-width="80px">
        <template v-slot="{ row }">
          <span>{{types.status[row.status]||'未知状态'}}</span>
        </template>
      </vxe-table-column>
      <vxe-table-column slot="table-item" field="check_time" title="处理时间" align="center" min-width="100px" />
      <vxe-table-column slot="table-item" title="操作" align="center" width="140" v-if="$hasAccess('device.repaire/edit')">
        <template v-slot="{ row }">
          <el-button size="small" plain @click="editOne(row)">状态变更</el-button>
        </template>
      </vxe-table-column>
    </Tables>

    <!-- 新建报修 -->
    <el-dialog title="新增报修" :visible.sync="addDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
      <el-form :model="addDialogFormData" :rules="rules" size="mini" ref="addForm" label-width="100px" class="form">
        <el-form-item label="所属设备" prop="device_id">
          <el-select v-model="addDialogFormData.device_id" @change="handelChangeDevice" placeholder="请选择报修设备">
            <el-option v-for="device in deviceList" :key="device.device_id" :label="device.device_name" :value="device.device_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属插座" prop="plug_id">
          <el-select v-model="addDialogFormData.plug_id" placeholder="请选择报修插座">
            <el-option v-for="plug in plugList" :key="plug.plug_id" :label="plug.plug_name" :value="plug.plug_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="附件图" prop="attach_id">
          <Upload :fileList="imageList" @getFileInfo="getFileInfo">
          </Upload>
        </el-form-item>
        <el-form-item label="备注" prop="detail">
          <el-input v-model="addDialogFormData.detail" type="textarea" clearable style="width:80%" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveAdd('addForm')" v-if="$hasAccess('device.repaire/add')">保 存
        </el-button>
        <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
        </el-button>
      </div>
    </el-dialog>

    <!-- 编辑状态 -->
    <el-dialog title="报修状态变更" :visible.sync="editDialogShow" :close-on-click-modal="false" :close-on-press-escape="false" width="500px">
      <el-form :model="editDialogFormData" :rules="editRules" size="mini" ref="editForm" label-width="100px" class="form">
        <el-form-item label="报修设备：">{{editDialogFormOriData.device_name}}</el-form-item>
        <el-form-item label="报修插座：">{{editDialogFormOriData.plug_name}}</el-form-item>
        <el-form-item label="附件图片：" v-if="editDialogFormOriData.attach&&editDialogFormOriData.attach.length>0">
          <el-image style="width:50px;height:50px;margin-right:10px;" v-for="(item,index) in editDialogFormOriData.attach" :key="item" :id="index" fit="cover" :src="item" :preview-src-list="editDialogFormOriData.attach">
          </el-image>
        </el-form-item>
        <el-form-item label="报修备注：">{{editDialogFormOriData.mark||'无'}}</el-form-item>
        <el-form-item label="报修状态：" prop="status">
          <el-radio-group v-model="editDialogFormData.status">
            <el-radio :label="20">待处理</el-radio>
            <el-radio :label="30">已确认</el-radio>
            <el-radio :label="40">已处理</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" plain icon="el-icon-check" @click="saveEdit()" :disabled="editDisabled" v-if="$hasAccess('device.repaire/edit')">保 存 状 态
        </el-button>
        <el-button size="small" plain icon="el-icon-close" @click="closeDialog">取 消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Tables from "@/components/tables";
import Upload from "@/components/upload";

export default {
  name: "device-repaire-list",
  components: { Tables, Upload },
  data() {
    return {
      // table信息
      tableName: "报修清单",
      isLoading: false,
      tableData: [],
      totalPage: 0,
      searchFormData: {},
      // 类型
      types: {
        status: {
          20: "待处理",
          30: "已确认",
          40: "已处理",
        },
      },
      // 新增报修
      addDialogShow: false,
      addDialogFormData: {},
      // 表单规则
      rules: {
        device_id: [
          { required: true, message: "请选择所属设备", trigger: "change" },
        ],
        plug_id: [
          { required: true, message: "请选择所属插座", trigger: "change" },
        ],
      },
      editRules: {
        status: [
          { required: true, message: "请选择报修状态", trigger: "change" },
        ],
      },
      // 选择列表
      deviceList: [],
      plugList: [],
      imageList: [],
      // 编辑报修状态
      editDialogShow: false,
      editDialogFormData: {},
      editDialogFormOriData: {},
      editNeedData: ["status"],
    };
  },
  computed: {
    editDisabled() {
      for (let item of this.editNeedData) {
        if (this.editDialogFormData[item] != this.editDialogFormOriData[item]) {
          return false;
        }
      }
      return true;
    },
  },
  mounted() {},
  activated() {
    this.$refs.xTable.refreshTable();
  },
  methods: {
    // 获取插座列表
    async getPlugList(device_id) {
      const params = {
        token: this.$store.state.user.token,
        page: 1,
        size: 100,
      };
      const res = await this.$api.Device.GetPlugList(params, {
        device_id: device_id,
      });
      this.plugList = res.data;
    },
    // 获取设备列表
    async getDeviceList() {
      const params = {
        token: this.$store.state.user.token,
        page: 1,
        size: 100,
      };
      const res = await this.$api.Device.GetDeviceList(params, {});
      this.deviceList = res.data;
    },
    // 获取表格列表
    async getList(currentPage, pageSize) {
      const params = {
        token: this.$store.state.user.token,
        page: currentPage,
        size: pageSize,
      };
      this.isLoading = true;
      const searchData = this.searchFormData;
      // 小区限制
      const group_id = this.$store.state.user.userInfo.group_id;
      if (!searchData.group_id && group_id) {
        searchData.group_id = group_id;
      }
      const res = await this.$api.Device.GetRepaireList(params, searchData);
      this.tableData = res.data;
      this.totalPage = res.total;
      this.isLoading = false;
    },
    // 清除搜索
    clearSearch() {
      this.searchFormData = {};
    },
    // 关闭弹窗
    closeDialog() {
      this.addDialogShow = false;
      this.editDialogShow = false;
    },
    // 重置表单
    resetFormData() {
      this.addDialogFormData = {
        device_id: "",
        plug_id: "",
        detail: "",
        attach_id: "",
      };
      this.imageList = [];
      this.editDialogFormData = {};
      if (this.$refs.addForm) {
        this.$refs.addForm.resetFields();
      }
      if (this.$refs.editForm) {
        this.$refs.editForm.resetFields();
      }
    },
    // 新建弹窗
    AddOne() {
      this.resetFormData();
      this.getDeviceList();
      this.addDialogShow = true;
    },
    // 编辑弹窗
    editOne(row) {
      this.resetFormData();
      const { record_id, device_name, plug_name, plug_num, status, mark } = row;
      const attach = [];
      for (let item of row.attach) {
        attach.push(item.url);
      }
      this.editDialogFormOriData = {
        record_id,
        device_name,
        plug_name,
        plug_num,
        status,
        mark,
        attach,
      };
      this.editDialogFormData = { record_id, status };
      this.editDialogShow = true;
    },
    // 改变设备
    handelChangeDevice(device_id) {
      this.addDialogFormData.plug_id = "";
      this.getPlugList(device_id);
    },
    // 附件
    getFileInfo(fileInfo) {
      this.addDialogFormData.attach_id = fileInfo;
    },
    // 新增保存
    saveAdd() {
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.addDialogFormData),
          };
          await this.$api.Device.AddRepaire(params);
          this.$notify({
            title: "成功",
            message: "新增成功",
            type: "success",
          });
          this.closeDialog();
          this.$refs.xTable.refreshTable();
        }
      });
    },
    // 编辑保存
    saveEdit() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          const params = {
            token: this.$store.state.user.token,
            form: JSON.stringify(this.editDialogFormData),
          };
          await this.$api.Device.EditRepaire(params);
          this.$notify({
            title: "成功",
            message: "状态变更成功",
            type: "success",
          });
          this.closeDialog();
          this.$refs.xTable.refreshTable();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
